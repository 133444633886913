export function getCommodityIconSrc(type) {
    const lowerCaseType = type.toLowerCase();

    switch (lowerCaseType) {
        case 'wind': return "/images/icon_wind.png";
        case 'easy': return "/images/breathe_easy.png";
        case 'solar': return "/images/icon_solar.png";
        case 'renewable': return "/images/icon_green.png";
        case 'carbon offset': return "/images/icon_gas.png";
        case 'eco rewards': return "/images/ecorewards.png";
        case 'gas giveback': return "/images/gasgiveback.png";
        default: return "";
    }
}

export function getEcoOrGasNewPlansName(plan_name) {
    if(plan_name.toLowerCase().includes("eco rewards")) {
        return "eco rewards";
    } else if(plan_name.toLowerCase().includes("gas giveback")) {
        return "gas giveback";
    } else return null;
}
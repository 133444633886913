import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    AsideBox,
    AsideBoxBody,
    AsideBoxHeader,
    PlanSummaryRow,
    PlanSummaryItem
} from './styles/Enrollment.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PlanDesc } from './PlanDesc'
import { getCommodityIconSrc, getEcoOrGasNewPlansName } from '../common'
import { Button } from './styles/Button.styled'
import { PlanProps } from '../types'
import isAdmin from '../utils/isAdmin'
import { StyledAgentPlanRow } from './styles/Plan.styled'
import { Collapse, CollapseProps } from 'antd'

interface PlanReviewProps {
    data: PlanProps[];
    isTx: boolean|undefined;
    handleShowPlanModal: Function;
    enrollmentStep: number;
    utility: any;
    utilityList: any;
}

export const PlanSummary: React.FC<PlanReviewProps> = ({
    data, isTx, handleShowPlanModal, enrollmentStep, utility, utilityList
}) => {
    const { t, i18n } = useTranslation('enrollment')
    const [ isAgent, setAgent ] = useState(false)
    const [ currentUtility, setCurrentUtility] = useState()

    useEffect(()=>{
        (async()=>{
            let _isAdmin = await isAdmin();
            setAgent(_isAdmin)
        })();
        
        try{
            Object.keys(utilityList).map((key, idx) => {
                //@ts-ignore
                const plans = data ? (data[key] ? [...data[key]].sort(sortByName) : data[key]) || [] : [];
                const findUtility = utilityList[key]?.find((item: any) => (item.utility_code == utility[key] || item.id_utility == utility[key]) );
                setCurrentUtility(findUtility)
            })
        }catch(err){
        }
    },[utilityList, utility])

    if (data.length === 0) return null;
    
    const displayDocs = (plan: any) => {
        const { efl_url, terms_url, yrac, docs } = plan;

        if (isTx) {
            return (
                <div style={{display:isAgent?"flex":"block",justifyContent:"space-between"}}>
                    {
                        efl_url &&
                        <PlanSummaryRow narrow>
                            <a href={efl_url} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">EFL</span>
                            </a>
                        </PlanSummaryRow>
                    }
                    {
                        terms_url &&
                        <PlanSummaryRow narrow>
                            <a href={terms_url} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">TOS</span>
                            </a>
                        </PlanSummaryRow>
                    }
                    {
                        yrac &&
                        <PlanSummaryRow narrow>
                            <a href={yrac} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="file-pdf" />
                                <span className="ms-2">YRAC</span>
                            </a>
                        </PlanSummaryRow>
                    }
                </div>
            )
        }
        if (docs) {
            const availableDocs = docs.filter((val: any) => val.display_in_plan_listing === true)
            return availableDocs.map((doc: any, idx: number) => (
                <PlanSummaryRow narrow key={idx}>
                    <a href={doc.url} target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon="file-pdf" />
                        <span className="ms-2">{doc.display_name}</span>
                    </a>
                </PlanSummaryRow>
            ))
        }
    }

    const planSummary = (isAgent:any) => {
        return data.map((plan: any, idx: number) => {
            const { plan_name, contract_term, cancel_fee, cancel_fee_type, price2000, rate, commodity_clean_name, plan_desc,
                energy_charge1,base_fee1,price500,price1000 } = plan;

                const items: CollapseProps['items'] = [
                    {
                        key: '1',
                        label: <div style={{fontSize:"15px",fontWeight:600,display:"flex",justifyContent:"space-between"}}>
                            <div>{`${t('Rate')}${isTx ? ` (${t('Based on 2,000 kWh')})` : ''}`}:</div>
                            <div style={{fontSize:"17px",fontWeight:700,color:"#254066"}}>{`${isTx ? price2000 : rate}¢`}</div>
                            </div>,
                        children: <div style={{background:"#FFFFFF"}}>
                                <div style={{fontSize:"12px",fontWeight:400,display:"flex",justifyContent:"space-between",color:"#254066"}}>
                                    <div>{t('Energy Charge')}:</div>
                                    <div style={{fontWeight:600}}>{`${parseFloat((Number(energy_charge1)*100)?.toFixed(2))}¢ per kWh `}</div>
                                </div>
                                <div style={{fontSize:"12px",fontWeight:400,display:"flex",justifyContent:"space-between",color:"#254066",paddingTop:"8px"}}>
                                    <div>{t('Base Fee')}:</div>
                                    <div style={{fontWeight:600}}> {`$${parseFloat((Number(base_fee1)*100)?.toFixed(2))} per bill month`}</div>
                                </div>
                                <div style={{fontSize:"12px",fontWeight:400,display:"flex",justifyContent:"space-between",color:"#254066",paddingTop:"8px"}}>
                                    {/* @ts-ignore */}
                                    <div>{`${currentUtility?.name} Charges`}:</div>
                                    {/* @ts-ignore */}
                                    <div style={{fontWeight:600}}>{`$${parseFloat(Number(currentUtility?.tdsp_FixedFee).toFixed(4))} per bill month & ${parseFloat(Number(currentUtility?.tdsp_PerUnitOfMeasure).toFixed(4))}¢ per kWh.`}</div>
                                </div>
                            </div>,
                    },
                  ];
            
                const items2: CollapseProps['items'] = [
                    {
                        key: '1',
                        label: <div style={{fontSize:"15px",fontWeight:600,display:"flex",justifyContent:"space-between"}}>
                            <div>{t('Plan Length')}:</div>
                            <div style={{fontSize:"17px",fontWeight:700,color:"#254066"}}>{contract_term} {t('Months')}</div>
                            </div>,
                        children: <></>,
                    },
                  ];
            
                const items3: CollapseProps['items'] = [
                    {
                        key: '1',
                        label: <div style={{fontSize:"15px",fontWeight:600,display:"flex",justifyContent:"space-between"}}>
                            <div>{t('Cancellation Fee')}:</div>
                            <div style={{fontSize:"17px",fontWeight:700,color:"#254066"}}>{`${cancel_fee} ${t(cancel_fee_type)}`}</div>
                            </div>,
                        children: <></>,
                    },
                  ];
            
                const onChange = (key: string | string[]) => {
                console.log(key);
                };

            return (
                <PlanSummaryItem key={idx}>
                    <div className="d-flex align-items-center mb-4">
                        {
                            (Boolean(getEcoOrGasNewPlansName(plan_name))) ? (
                                <div className="renewable_type me-2">
                                    <img src={getCommodityIconSrc(getEcoOrGasNewPlansName(plan_name))} width="32" height="32" alt="" />
                                </div>
                            ) : (
                                commodity_clean_name ?
                                <div className="renewable_type me-2">
                                    <img src={getCommodityIconSrc(commodity_clean_name)} width="32" height="32" alt="" />
                                </div> :
                                (
                                    plan_name.toLowerCase().includes("easy") && <div className="renewable_type me-2">
                                        <img src={getCommodityIconSrc("easy")} width="32" height="32" alt="" />
                                    </div>
                                )
                            )
                        }
                        <div className="plan-name">{plan_name}</div>
                    </div>
                    {
                        i18n.language === 'en' &&
                        <PlanDesc desc={plan_desc} />
                    }
                    {(isAgent && isTx)?
                            <div>
                                {(isAgent && Boolean(Number(price500) || Number(price1000) || Number(price2000))) && <div style={{display:"flex",justifyContent:"space-between",marginTop:"15px",
                                    borderRadius: "8px",
                                    padding: "8px 4px",
                                    border: "2px solid #B4D6FF"
                                }}>
                                    <div style={{textAlign:"center"}}>
                                        <div style={{fontWeight:600,fontSize:"11px"}}>Average Monthly Use</div>
                                        <div style={{fontWeight:500,fontSize:"11px"}}>Average price ¢ / kWh</div>
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <div style={{fontWeight:600,fontSize:"11px"}}>500 kWh</div>
                                        <div style={{fontWeight:500,fontSize:"11px"}}>{`${price500}¢`}</div>
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <div style={{fontWeight:600,fontSize:"11px"}}>1,000 kWh</div>
                                        <div style={{fontWeight:500,fontSize:"11px"}}>{`${price1000}¢`}</div>
                                    </div>
                                    <div style={{textAlign:"center"}}>
                                        <div style={{fontWeight:600,fontSize:"11px"}}>2,000 kWh</div>
                                        <div style={{fontWeight:500,fontSize:"11px"}}>{`${price2000}¢`}</div>
                                    </div>
                                </div>}
                                <Collapse
                                    onChange={onChange}
                                    expandIconPosition={'end'}
                                    items={items}
                                    style={{
                                        color:"#446596",
                                        background:"#FFFFFF",
                                        border:"none",
                                        margin:"8px 0px"
                                    }}
                                />
                                <Collapse
                                    className="no-arrow"
                                    onChange={onChange}
                                    expandIconPosition={'end'}
                                    activeKey={[]}
                                    items={items2}
                                    style={{
                                        color:"#446596",
                                        background:"#FFFFFF",
                                        border:"none",
                                        margin:"8px 0px"
                                    }}
                                />
                                <Collapse
                                    className="no-arrow"
                                    onChange={onChange}
                                    expandIconPosition={'end'}
                                    activeKey={[]}
                                    items={items3}
                                    style={{
                                        color:"#446596",
                                        background:"#FFFFFF",
                                        border:"none",
                                        margin:"8px 0px"
                                    }}
                                />
                                <div style={{color:"#828282",fontSize:"14px",fontWeight:400,marginTop:"8px"}}>
                                    Please, read, print, and save these documents for your referrence.
                                </div>
                                <style>
                                    {
                                        `:where(.css-dev-only-do-not-override-xu9wm8).ant-collapse .ant-collapse-content>.ant-collapse-content-box  {
                                            background:#FFFFFF;
                                            padding: 8px 0px;
                                        }
                                        :where(.css-dev-only-do-not-override-xu9wm8).ant-collapse .ant-collapse-content {
                                            border-top: 2px solid #25406626;
                                            margin: 0px 16px;
                                            border-bottom:none;
                                        }
                                        .no-arrow .anticon svg {
                                            display: none;
                                        }
                                        `
                                    }
                                </style>
                            </div>
                        :
                        <div>
                            <PlanSummaryRow>
                                <label>{`${t('Rate')} ${isTx ? ` (${t('Based on 2,000 kWh')})` : ''}`}:</label>
                                <span>
                                    <b>{`${isTx ? price2000 : rate}¢`}</b>
                                </span>
                            </PlanSummaryRow>
                            {isAgent && <PlanSummaryRow>
                                <label>{t('Energy Charge')}:</label>
                                <span>
                                    {`${Number(energy_charge1)?.toFixed(2)}¢ per kWh `}
                                </span>
                            </PlanSummaryRow>}
                            <PlanSummaryRow>
                                <label>{t('Plan Length')}:</label>
                                <span>{contract_term} {t('Months')}</span>
                            </PlanSummaryRow>
                            {isAgent && <PlanSummaryRow>
                                <label>{t('Base Fee')}:</label>
                                <span>
                                    {`$${(Number(base_fee1)*100)?.toFixed(2)} per bill month`}
                                </span>
                            </PlanSummaryRow>}
                            <PlanSummaryRow>
                                <label>{t('Cancellation Fee')}:</label>
                                <span style={{textAlign:'right'}}>
                                    {`${cancel_fee} ${t(cancel_fee_type)}`}
                                </span>
                            </PlanSummaryRow>
                        </div>
                        }
                    <div className="plan-links mt-3">
                        { displayDocs(plan) }
                    </div>
                </PlanSummaryItem>
            )
        })
    }

    return (
        <AsideBox>
            <AsideBoxHeader>
                <h5>{t('Your Selected Plan')}</h5>
            </AsideBoxHeader>
            <AsideBoxBody>
                <div>
                    { planSummary(isAgent) }
                </div>
                {
                    enrollmentStep === 1 &&
                    <Button className="mt-3" secondary sm onClick={() => handleShowPlanModal()}>{t('View More Plans')}</Button>
                }
            </AsideBoxBody>
        </AsideBox>
    )
}
